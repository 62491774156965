
import DropdownFilterButton from '@/components/jobs/JobsList/DropdownFilterButton.vue'
import PDECheckbox from '@/components/pde/PDECheckbox.vue'
export default {
  name: 'CheckList',
  components: {
    DropdownFilterButton,
    PDECheckbox,
  },
  props: {
    label: {
      type: String,
      default: 'Select values',
    },
    labelTooltip: {
      type: String,
      default: 'Select values',
    },
    values: {
      type: Object,
      required: true,
    },
    selectedValues: {
      type: Array,
      required: true,
    },
  },
  emits: ['itemClick'],
  methods: {
    handleItemClick(key) {
      this.$emit('itemClick', key)
    },
    isChecked(key) {
      return this.selectedValues.find(f => f === key) !== undefined
    },
  },
}
