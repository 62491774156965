
import PDEHeading from '@/components/pde/PDEHeading.vue'
import PDEButton from '@/components/pde/PDEButton.vue'
import isEmpty from 'lodash.isempty'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'PreferenceFormContainer',

  components: {
    PDEHeading,
    PDEButton,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    buttonLabel: {
      type: String,
      default: 'Edit',
    },
    analyticsData: {
      type: Object,
      default: () => ({}),
    },
    hideButton: {
      type: Boolean,
      default: false,
    },
    waitingStatus: {
      type: Boolean,
      default: false,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['buttonClick', 'cancelClick'],
  computed: {
    ...mapGetters('identity', ['loggedInAsRep']),
    getButtonLabel() {
      return this.editMode ? 'Save Changes' : this.buttonLabel
    },
  },
  methods: {
    ...mapActions('analytics', ['sendClickEvent']),
    handleClick() {
      if (this.editMode) this.handleAnalytics()
      this.$emit('buttonClick')
    },
    handleCancel() {
      this.$emit('cancelClick')
    },
    handleAnalytics() {
      if (!isEmpty(this.analyticsData)) {
        this.sendClickEvent({
          name: this.analyticsData.clickName,
          text: this.analyticsData.clickText,
          area: this.analyticsData.clickArea,
          position: this.analyticsData.clickPosition,
          detail: this.analyticsData.clickDetail,
        })
      }
    },
  },

}
