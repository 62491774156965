
import { mapState, mapGetters, mapActions } from 'vuex'
import PreferenceFormContainer from './PreferenceFormContainer.vue'
import PDEHeading from '@/components/pde/PDEHeading.vue'
import dayjs from 'dayjs'

export default {
  name: 'AvailableToWork',
  components: {
    PreferenceFormContainer,
    PDEHeading,

  },
  props: {
    isEditing: {
      type: Boolean,
      default: false,
    },
    hideButton: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['cancel', 'edit', 'save', 'date'],
  data() {
    return {
      selectedPreferences: {},
    }
  },
  computed: {
    ...mapGetters('workingPreferences', ['getWorkingPreferences']),
    ...mapState('workingPreferences', ['waiting', 'availableToWork', 'nextAvailableDate']),

    dateLabel() {
      return this.nextAvailableDate ? this.formatDate(this.nextAvailableDate) : '\u00A0'
    },
    dateTitle() {
      return this.selectedPreferences.availableToWork ? 'When do you want to start working?' : 'Please provide an approximate date of when you can start working.'
    },
  },
  mounted() {
    this.selectedPreferences = { ...this.getWorkingPreferences }
  },
  methods: {
    ...mapActions('analytics', ['sendClickEvent']),

    getNewPreferences() {
      return {
        availableToWork: this.selectedPreferences.availableToWork,
        nextAvailableDate: this.selectedPreferences.nextAvailableDate || null,
      }
    },

    handleCancel() {
      this.$emit('cancel', this.getNewPreferences())
    },

    handleClick() {
      if (this.isEditing) {
        this.$emit('save', this.getNewPreferences())
      } else {
        this.selectedPreferences = { ...this.getWorkingPreferences }
        this.$emit('edit')
      }
    },

    emitClickEvent() {
      this.$emit('date')
    },

    availableToWorkRadioAnalytics(availableToWork) {
      this.sendClickEvent({
        name: 'Available To Work Radio Click',
        text: availableToWork ? 'Yes' : 'No',
        area: 'profile/availability-preferences',
      })
    },

    formatDate(date) {
      return dayjs(date).format('MM-DD-YYYY')
    },

    maxDate() {
      return dayjs().add(5, 'year').format('YYYY-MM-DD')
    },

    minDate() {
      return dayjs().utc().format('YYYY-MM-DD')
    },
  },
}

