
import { mapState, mapGetters, mapActions } from 'vuex'
import PreferenceFormContainer from './PreferenceFormContainer.vue'
import PDEHeading from '@/components/pde/PDEHeading.vue'
import CheckList from './CheckList.vue'

export default {
  name: 'AvailableToWork',
  components: {
    PreferenceFormContainer,
    PDEHeading,
    CheckList,
  },
  props: {
    isEditing: {
      type: Boolean,
      default: false,
    },
    hideButton: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['cancel', 'edit', 'save'],
  data() {
    return {
      selectedPreferences: {
        preferredStates: [],
        providerWorkPreferences: [],
      },
      providerWorkPreferencesList: {
        Telehealth: 'Telehealth',
        'In-Patient': 'In patient',
        'Out-Patient': 'Out patient',
      },
    }
  },
  computed: {
    ...mapGetters('workingPreferences', ['getWorkingPreferences']),
    ...mapGetters('jobs', ['getStateList']),
    ...mapState('workingPreferences', ['waiting', 'activeLicenses', 'preferredStates', 'providerWorkPreferences']),

    preferredStateList() {
      return this.preferredStates?.join(', ') || '\u00A0'
    },
    activeLicencesList() {
      return this.activeLicenses?.join(', ') || '\u00A0'
    },
    clinicalPracticeList() {
      return this.providerWorkPreferences?.join(', ') || '\u00A0'
    },
  },
  methods: {
    ...mapActions('analytics', ['sendClickEvent']),
    ...mapActions('assignment', ['toggleMyTeamsDrawer']),
    getNewPreferences() {
      const result = {
        preferredStates: this.selectedPreferences.preferredStates,
        providerWorkPreferences: this.selectedPreferences.providerWorkPreferences,
      }
      return result
    },
    handleCancel() {
      this.$emit('cancel', this.getNewPreferences())
    },

    handleClick() {
      if (this.isEditing) {
        this.$emit('save', this.getNewPreferences())
      } else {
        this.selectedPreferences.preferredStates = [...this.getWorkingPreferences.preferredStates]
        this.selectedPreferences.providerWorkPreferences = [...this.getWorkingPreferences.providerWorkPreferences]
        this.$emit('edit')
      }
    },

    handleStateClick(state) {
      const index = this.selectedPreferences.preferredStates.indexOf(state)
      index === -1 ? this.selectedPreferences.preferredStates.push(state) : this.selectedPreferences.preferredStates.splice(index, 1)

      this.sendClickEvent({
        name: 'Interested states change',
        text: 'Interested states',
        area: 'profile/working-preferences',
      })
    },

    handleProviderWorkPreferencesClick(preference) {
      const index = this.selectedPreferences.providerWorkPreferences.indexOf(preference)
      index === -1 ? this.selectedPreferences.providerWorkPreferences.push(preference) : this.selectedPreferences.providerWorkPreferences.splice(index, 1)

      this.sendClickEvent({
        name: 'Practice settings change',
        text: 'Practice settings',
        area: 'profile/working-preferences',
      })
    },

    getLabel(list, defaultLabel) {
      const size = list.length - 1
      if (size === -1) return defaultLabel
      return size === 0 ? list[0] : `${list[0]}, +${size}`
    },
  },
}

